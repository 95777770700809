<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" @input="loadHistoryCustom" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <Spinner size="medium" line-fg-color="#000" :speed="1" />
            </div>
            <div
                v-if="!hasData && !isLoading"
                class="l-stack l-center l-padded"
            >
                <span>{{ $t('nodata') }}</span>
            </div>

            <ApexCharts
                v-if="!isLoading && hasData"
                height="420px"
                :options="chartOptions"
                :series="series"
            />

            <div
                v-if="!isLoading && hasData"
                class="l-inline l-padded l-justify-end"
            >
                <span
                    >{{ $t('workinghours') }}:
                    <strong>{{ workingHoursHumanized }}</strong></span
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import Spinner from 'vue-simple-spinner'
import ApexCharts from 'vue-apexcharts'

import { httpHelper } from '@/utils'

import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'

export default {
    name: 'AssetChartsView',
    components: {
        ApexCharts,
        DateRangeInput,
        Spinner,
    },
    mixins: [ChartHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        datetimeUTC: false,
                    },
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
            dataType: 'd1',
        }
    },
    computed: {
        hasData() {
            return !!(this.series[0].data.length > 0)
        },
        workingHoursHumanized() {
            let data = this.series[0].data

            data = data.filter(item => item.isOn)

            const duration = data.reduce((accDuration, item) => {
                const dateStart = moment(item.y[0])
                const dateEnd = moment(item.y[1])

                return accDuration.add(moment.duration(dateEnd.diff(dateStart)))
            }, moment.duration(0))

            let hours = duration.asHours()
            duration.subtract(parseInt(hours), 'hours')
            let minutes = duration.asMinutes()

            return (
                (parseInt(hours) < 10 ? '0' : '') +
                parseInt(hours) +
                'h ' +
                (parseInt(minutes) < 10 ? '0' : '') +
                parseInt(minutes) +
                'm'
            )
        },
    },
    methods: {
        loadHistoryCustom(dates) {
            this.loadHistoryFromDateRange({
                startDate: moment(dates.startDate),
                endDate: moment(dates.endDate),
            })
        },
        async loadHistoryFromDateRange(dates) {
            this.isLoading = true
            this.startDate = dates.startDate
            this.endDate = dates.endDate
            this.customRange = {
                startDate: this.startDate.toDate(),
                endDate: this.endDate.toDate(),
            }
            const results = await this.loadData()
            this.addHistoryDataFromResponse(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []
            let historyUrl = [
                'measurements/?',
                'timestamp_min=' + encodeURIComponent(this.startDate.format()),
                '&timestamp_max=' + encodeURIComponent(this.endDate.format()),
                '&tracker=' + this.id,
                '&fields=timestamp,sensor_data',
                '&limit=' + process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST,
            ].join('')

            while (historyUrl) {
                const { data } = await httpHelper.get(historyUrl)
                historyUrl = data.next || null
                results = [...results, ...data.results]
            }

            return results
        },
        addHistoryDataFromResponse(results) {
            let chartData = results.filter(item =>
                Object.prototype.hasOwnProperty.call(
                    item.sensor_data,
                    this.dataType
                )
            )

            chartData = chartData.map(item => [
                item.timestamp,
                !!item.sensor_data[this.dataType],
            ])

            chartData.reverse()
            let onData = []
            let offData = []

            for (let i = 0; i < chartData.length; i++) {
                let item = chartData[i]
                let lastSameDataIndex = i
                for (
                    let x = i + 1;
                    x < chartData.length && chartData[x][1] == chartData[i][1];
                    x++
                ) {
                    lastSameDataIndex = x
                }

                if (chartData[lastSameDataIndex + 1] !== undefined) {
                    let data = {
                        x: item[1] ? this.$t('on') : this.$t('off'),
                        isOn: item[1],
                        y: [
                            new Date(item[0]).getTime(),
                            new Date(
                                chartData[lastSameDataIndex + 1][0]
                            ).getTime(),
                        ],
                        fillColor: item[1] ? '#77da57' : '#ed4648',
                    }
                    if (item[1]) {
                        onData.push(data)
                    } else {
                        offData.push(data)
                    }
                }

                i = lastSameDataIndex
            }

            this.series = [
                {
                    data: [...onData, ...offData], // ensures that "on" is always the top in the graph
                },
            ]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "operatingstatus": "Operating Status",
        "on": "on",
        "off": "off",
        "nodata": "No data for this time period",
        "workinghours": "Hours of operation"
    },
    "de": {
        "operatingstatus": "Betriebsstatus",
        "on": "ein",
        "off": "aus",
        "nodata": "Keine Daten für diese Zeitperiode",
        "workinghours": "Betriebsstunden"
    },
    "it": {
        "operatingstatus": "Stato di funzionamento",
        "on": "on",
        "off": "off",
        "nodata": "Nessun dato per questo periodo di tempo",
        "workinghours": "Orario di funzionamento"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
    @include respond-to('for-tablet-down') {
        display: block;
    }
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
    @include respond-to('for-tablet-down') {
        border: none;
    }
}

input[type='checkbox'] {
    margin-left: 50px;
}

label {
    padding-left: 10px;
}

.datepicker {
    width: 20%;
    min-width: 350px;

    @include respond-to('for-tablet-down') {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
